.tabIconButton {
  border-radius: 12px;
  background: var(--Card, #fff);
  box-shadow: -5px 12px 22px 0px rgba(55, 137, 42, 0.1);
  padding: 6.5px 0px;
}

.divider {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 10px;

  &::after {
    content: "";
    height: 25px;
    width: 2.5px;
    background-color: #e0e0e0;
    margin-left: 10px;
  }

  &:last-child {
    padding-left: 0;
    &::after {
      content: none;
    }
  }
}
