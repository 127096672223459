.googleMapModalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ffffff;
  z-index: 100000;
  overflow-x: hidden;
}

.input-style {
  box-sizing: border-box;
  border: 1px solid transparent;
  width: 250px;
  height: 40px;
  padding: 0 12px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  font-size: 14px;
  outline: none;
  text-overflow: ellipsis;
  position: absolute;
  top: 10px;
  right: 60px;
}

.autocompleteList {
  position: absolute;
  top: 52px;
  right: 60px;
  width: 250px;
  max-height: 200px;
  overflow-y: auto;
  background-color: #ffffff;
  border: 1px solid #f5f6f7;
  z-index: 1000;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.3);
  list-style-type: none;
  padding: 0;

  .autocompleteItem {
    font-size: 12px;
    color: #69768b;
    padding: 10px;
    cursor: pointer;

    &:hover {
      background-color: #f5f6f7;
    }
  }
}

.autocompleteList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.autocompleteList::-webkit-scrollbar-track {
  background: rgb(233, 233, 233);
}

.autocompleteList::-webkit-scrollbar-thumb {
  background-color: rgb(187, 187, 187);
  border-radius: 20px;
}
