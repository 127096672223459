.card {
  border-radius: 8px;
  background: var(--Card, #fff) !important;
  box-shadow: 0px 12px 40.9px 0px rgba(38, 51, 77, 0.05);
  border: none !important;
  height: 100% !important;

  .title {
    color: var(--Light-Text, #69768b);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 1px;
  }

  .value {
    color: var(--Dark-Text, #252724);
    font-size: 32px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 1px;

    span {
      font-size: 12px;
    }
  }

  .percentage {
    color: var(--Light-Text, #69768b);
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 1px;
  }
}
