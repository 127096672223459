.tabContainer {
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 6px 28.5px 0px rgba(38, 51, 77, 0.06);
  border: 1px solid #f7f5f5;
}

.indicator {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.redIndicator {
  background-color: #e74c3c;
}

.redLight {
  background-color: #ffe7e4;
}

.grayLight {
  background-color: #eef3f6;
}

.yellowLight {
  background-color: #fbf0df;
}

.greenLight {
  background-color: #e0faeb;
}

.grayIndicator {
  background-color: #85929e;
}

.yellowIndicator {
  background-color: #f39c12;
}

.lightGreenIndicator {
  background-color: #2ecc71;
}
