.card {
  background-color: #ffffff;
  box-shadow: 0px 14px 43.9px 0px rgba(38, 51, 77, 0.05);
  border-radius: 8px;
  & .textOne {
    color: var(--Dark-Text, #252724);
    font-size: 14.975px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  & .textOneTruncate {
    max-width: 220px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    cursor: context-menu !important;
  }
  & .textTow {
    color: var(--Light-Text, #69768b);
    font-size: 12.836px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  & .textTruncate {
    max-width: 150px !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    cursor: context-menu !important;
  }
  & .toolTip {
    color: #252724;
    font-size: 12px;
    font-weight: 500;
    background-color: #d8d6f9;
    border-radius: 8px;
    border: 1px solid #2f2a89;
    z-index: 100000;
  }
  & .toolTipArrow {
    color: #fff;
    border-right: 1px solid #2f2a89;
    border-bottom: 1px solid #2f2a89;
  }
}

.selectedCardColor {
  background-color: #d8d6f9;
}

@media (min-width: 992px) and (max-width: 1090px) {
  .card {
    & .textTruncate {
      max-width: 180px !important;
    }
  }
}

@media (min-width: 481px) and (max-width: 561px) {
  .card {
    & .textTruncate {
      max-width: 180px !important;
    }
  }
}

@media (max-width: 481px) {
  .card {
    & .textTruncate {
      max-width: 130px !important;
    }
  }
}

@media (max-width: 381px) {
  .card {
    & .textTruncate {
      max-width: 110px !important;
    }
  }
}
